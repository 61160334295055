/**
 * Passed as the type of any given `callback` pathways prop.
 */
export const CallbackState = {
	Close: 'autofi-close',
	Loading: 'autofi-loading',
	Finished: 'autofi-finished',
	Error: 'autofi-error',
};

export const OfferType = { FINANCING: 'finance', LEASING: 'lease', CASH: 'cash' };

export const View = {
	CREDIT_APP: 'CREDIT_APP',
	DEPOSIT: 'DEPOSIT',
	DRIVE_TOGETHER: 'DRIVE_TOGETHER',
	EXPLORE_PAYMENTS: 'EXPLORE_PAYMENTS',
	LOANAPP: 'LOANAPP',
	PRIVATE_OFFERS: 'PRIVATE_OFFERS',
	REQUEST_MORE_INFO: 'REQUEST_MORE_INFO',
	TEST_DRIVE: 'TEST_DRIVE',
	THANK_YOU: 'THANK_YOU',
};

export const OEMs = {
	FORD: 'Ford',
};

export const Pathways = {
	CreditApp: 'creditapp',
	Deposit: 'deposit',
	DriveTogether: 'drivetogether',
	ExplorePayments: 'explorepayments',
	PrivateOffers: 'privateoffers',
	RequestMoreInfo: 'requestinfo',
	StandAloneCreditApp: 'standalonecreditapp',
	TestDrive: 'testdrive',
	TradeIn: 'tradein',
	UnlockPricing: 'unlockpricing',
};

/**
 * Passed as part of the pathways API so the invoker can choose if they want
 * to open DM with the ExplorePayments panel or the panel where we start the
 * loan application.
 */
export const DealMakerPanel = {
	ExplorePayments: 'explorePayments',
	StartCreditApp: 'startCreditApp',
};

// Capitalization intentionally doesn't match Pathways values above because
// this is how ctaTypes are stored in db.
export const vehiclelessCtaTypes = ['creditApp', 'standAloneCreditApp'];

export const ProductionTestUserEmails = [
	'prodtest-1@autofi.io',
	'prodtest-2@autofi.io',
	'prodtest-3@autofi.io',
	'prodtest-4@autofi.io',
	'prodtest1@autofi.com',
	'training@autofi.io',
	'training@autofi.com',
	'prodtest-decline@autofi.io',
];

export const AmplitudeLabelComponents = {
	Label: {
		CustomerMessageDisplayed: 'Dealmaker: Customer Message Displayed',
		PathwayCreditEstimator: 'Pathway: credit-estimator',
		PathwayPrivateOfferDisplay: 'Pathway: private-offer: Offer Decision Displayed',
		PathwayStart: 'Pathway: start',
		PathwayStartAutoload: 'Pathway: start: autoload',
		PathwaySubmit: 'Pathway: submit',
	},
	Action: {
		Click: 'click',
		Load: 'load',
		PrivateOfferApplySavingsClick: 'private-offer: Apply Savings Clicked',
	},
	Steps: {
		LeadForm: 'LeadForm',
		PersonalEstimate: 'PersonalEstimate',
		PromoScreen: 'PromoScreen',
		ThankYou: 'ThankYou',
	},
};

export const TestAppNames = ['Samuel Testmorris', 'Lisa Snair', 'Jonathan Consumer', 'Jonathon Consumer'];

export const VehicleStatus = {
	Error: 'Error',
	NoData: 'NoData',
	OK: 'OK',
	Pending: 'Pending',
};

export const iFrameMessages = {
	DriveTogetherButtonClicked: 'drive-together-button-clicked',
};

export const SuffixOptions = [
	{
		text: 'None',
		value: 'None',
	},
	{
		text: 'SR',
		value: 'SR',
	},
	{
		text: 'JR',
		value: 'JR',
	},
	{
		text: 'I',
		value: 'I',
	},
	{
		text: 'II',
		value: 'II',
	},
	{
		text: 'III',
		value: 'III',
	},
	{
		text: 'IV',
		value: 'IV',
	},
	{
		text: 'V',
		value: 'V',
	},
];
