import { h, Component } from 'preact';

import ModalSequence from '~ui/components/modalSequence';
import ConsumerMFAClient from '~lib/ui/components/consumerMFAClient';
import ExplorePaymentsForm from './explorePaymentsForm';
import { Pathways } from '~lib/enum';

class ExplorePaymentsModalSequence extends Component {
	state = {
		onCompleteMFA: null,
	};

	setOnCompleteMFA = (onCompleteMFA) => {
		this.setState({ onCompleteMFA });
	};

	render = (props, state) => {
		return (
			<ModalSequence
				{...props}
				onCompleteMFA={state.onCompleteMFA}
				pages={[ExplorePaymentsForm, ConsumerMFAClient]}
				pathway={Pathways.ExplorePayments}
				setOnCompleteMFA={this.setOnCompleteMFA}
			/>
		);
	};
}

export default ExplorePaymentsModalSequence;
