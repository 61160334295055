import { createPortal } from 'preact/compat';
import { h, Component, Fragment } from 'preact';
import get from 'lodash/get';

import { VehicleStatus } from '~lib/enum';
import DdcLocationCta from '~ui/components/ddcLocationCta';
import DepositButton from '~ui/components/depositButton';
import ExplorePaymentsButton from '~ui/components/explorePaymentsButton';
import PaymentDisclaimer from '~ui/components/paymentDisclaimer';
import DriveTogetherIframeButton from '~ui/components/driveTogetherIframeButton';
import * as utils from '~ui/utils';

// This component handles most of the CTAs, associated with vehicles, that
// we render. It does not handle vehicleless CTAs like the credit app
// banner. It handles buttons inserted using the location functions
// (insert) in DDC's API, but not buttons inserted using DDC's intent
// functions (insertCallToAction).

export default ({
	applicant,
	ctaTypeClickHandlerMap,
	currentDealer,
	dictionary,
	driveDealer,
	estimate,
	isInStore,
	pageType,
	trackId,
	vehicle,
}) => {
	const { buttonTextOverrideI18n, showPaymentDetailsOnButton } = currentDealer.websiteSettings.ui[pageType];
	const globalButtonTextOverride = utils.getI18nString(currentDealer, buttonTextOverrideI18n);
	const dealer = vehicle.dealer || currentDealer;
	const { financeDisclosureCopy, leaseDisclosureCopy } = dealer.websiteSettings.ui.features;
	const paymentInterval = currentDealer.settings.consumerFlow.paymentIntervals.defaultInterval;
	const brand = get(vehicle, 'dealer.brand');

	const waitingForVehicleData = vehicle.status === VehicleStatus.Pending;

	const lockedPricingEnabled = utils.lockedPricingIsEnabled(currentDealer, vehicle);
	const isLockedPricing = utils.pricingIsLocked(vehicle, currentDealer, applicant);

	const explorePaymentsButtonAttributes = {
		dictionary,
		estimate,
		isLockedPricing,
		paymentInterval,
		showPaymentDetailsOnButton,
		waitingForVehicleData,
	};

	// Don't try to place >1 explore payments button on non-DDC sites, even if
	// we have >1 selector, to maintain behavior of dealers with multiple
	// selectors that were specified when we couldn't support >1 explore
	// payments button.
	// TODO: fix this to make it more consistent and flexible.
	const usefulCtaStuff = vehicle.ui.autofiCtaStuff.filter((ctaDetails) => ctaDetails.$ctaContainer);
	const nonDdcExplorePaymentsCtaDetails = usefulCtaStuff.find(
		(ctaDetails) => ctaDetails.ctaType === 'startApplication' && !ctaDetails.usesDdcLocation
	);
	const allOtherCtaStuff = usefulCtaStuff.filter(
		(ctaDetails) => !(ctaDetails.ctaType === 'startApplication' && !ctaDetails.usesDdcLocation)
	);
	const autofiCtaStuffToRender = [nonDdcExplorePaymentsCtaDetails, ...allOtherCtaStuff].filter(Boolean);

	// TODO: make ExplorePaymentsButton and PaymentDisclaimer figure out
	// lockedPricingEnabled and isLockedPricing on their own.

	return autofiCtaStuffToRender.map((ctaDetails) => {
		const {
			$ctaContainer,
			borderRadius,
			buttonTextOverride: individualButtonTextOverride,
			colors,
			ctaType,
			usesDdcLocation,
		} = ctaDetails;

		const onClick = (clickEvent) => ctaTypeClickHandlerMap[ctaType](vehicle.vin, { clickEvent });

		const buttonTextOverride =
			individualButtonTextOverride || (ctaType === 'startApplication' ? globalButtonTextOverride : null);

		const shouldPlaceDdcLocationButton = ctaType !== 'driveTogether' && usesDdcLocation;

		return createPortal(
			<Fragment>
				{shouldPlaceDdcLocationButton && (
					<DdcLocationCta
						{...(ctaType === 'startApplication' ? explorePaymentsButtonAttributes : {})}
						brand={brand}
						borderRadius={borderRadius}
						buttonTextOverride={buttonTextOverride}
						colors={colors}
						ctaType={ctaType}
						dictionary={dictionary}
						onClick={onClick}
					/>
				)}
				{ctaType === 'deposit' && !usesDdcLocation && (
					<DepositButton
						buttonTextOverride={buttonTextOverride}
						buttonColors={colors}
						compact={pageType === 'listings'}
						dictionary={dictionary}
						onClick={onClick}
					/>
				)}
				{ctaType === 'startApplication' && !usesDdcLocation && (
					<ExplorePaymentsButton
						{...explorePaymentsButtonAttributes}
						borderRadius={borderRadius}
						buttonTextOverride={buttonTextOverride}
						colors={colors}
						compact={pageType === 'listings'}
						onClick={onClick}
					/>
				)}
				{ctaType === 'startApplication' &&
					showPaymentDetailsOnButton &&
					!isLockedPricing &&
					Boolean(estimate) &&
					estimate.offerType !== 'cash' && (
						<PaymentDisclaimer
							dealer={currentDealer}
							dictionary={dictionary}
							estimate={estimate}
							financeDisclosureCopy={financeDisclosureCopy}
							isInStore={isInStore}
							leaseDisclosureCopy={leaseDisclosureCopy}
							lockedPricingEnabled={lockedPricingEnabled}
							pageType={pageType}
							trackId={trackId}
							vehicle={vehicle}
						/>
					)}
				{ctaType === 'driveTogether' && driveDealer?.code && (
					<DriveTogetherIframeButton dealerCode={driveDealer.code} vin={vehicle.vin} onClick={onClick} />
				)}
			</Fragment>,
			$ctaContainer
		);
	});
};
